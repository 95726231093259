<template>
  <b-container fluid="">
    <b-row>
      <b-col sm="12" lg="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Tambah SPT</h4>
          </template>
          <template v-slot:body>
            <form >
              <div class="form-row">
                <b-col md="12" class="mb-3">
                  <label for="no_spt">Nama Pelaksana<strong>*</strong></label>
                  <v-select :options="opt.pegawai" v-model="form.pelaksana_id" @input="initPelaksana" class="style-chooser" 
                  >
                  </v-select>
                </b-col>
                <b-col md="12" class="mb-3">
                  <label for="no_spt">Nama Pengikut</label>
                  <v-select multiple :options="opt.pegawai" :reduce="code => code.code" v-model="form.pegawai_id" class="style-chooser"
                    :selectable="(option) => !option.label.includes(form.pelaksana_id.label)"
                  >
                  </v-select>
                </b-col>
                <b-col md="6" class="mb-3">
                  <label for="tgl_berangkat">Tanggal SPT<strong>*</strong></label>
                  <flat-pickr class="form-control" :config="config.spt" id="tgl_berangkat" v-model="form.tgl_spt"></flat-pickr>
                </b-col>
                <b-col md="6" class="mb-3">
                  <label for="phone">Pejabat Penanda Tangan Surat<strong>*</strong></label>
                  <v-select :options="opt.pttd" :reduce="id => id.id" v-model="form.pttd_id" class="style-chooser">
                  </v-select>
                </b-col>
                <b-col md="6" class="mb-3">
                  <label for="bidang">Anggaran<strong>*</strong></label>
                  <input type="text" v-model="form.anggaran_text" class="form-control" @click="MakModalHandler" readonly>
                </b-col>
                <b-col md="6" class="mb-3">
                  <label for="pengguna_anggaran">Pengguna Anggaran<strong>*</strong></label>
                  <input type="text" class="form-control" id="pengguna_anggaran" v-model="form.pengguna_anggaran_text" readonly>
                </b-col>
                <b-col md="6" class="mb-3">
                  <label for="pptk">Pejabat Pelaksana Teknis Kegiatan<strong>*</strong></label>
                  <input type="text" class="form-control" id="pptk" v-model="form.pptk_text" readonly>
                </b-col>
                <b-col md="6" class="mb-3">
                  <label for="bendahara">Bendahara Pengeluaran<strong>*</strong></label>
                  <input type="text" class="form-control" id="bendahara" v-model="form.bendahara_text" readonly>
                </b-col>
                <b-col md="6" class="mb-3">
                  <label for="name">Jenis Perjalanan Dinas<strong>*</strong></label>
                  <v-select :options="opt.jenis_dinas" v-model="form.jenis_dinas" @input="initDaerah" class="style-chooser"></v-select>
                </b-col>
                <b-col md="6" class="mb-3">
                  <label for="name">Alat Angkut<strong>*</strong></label>
                  <v-select :options="opt.transportasi" v-model="form.transportasi" class="style-chooser"></v-select>
                </b-col>
                <b-col md="6" class="mb-3">
                  <label for="name">Daerah Asal<strong>*</strong></label>
                  <v-select :options="opt.daerah" v-model="form.daerah_asal" :reduce="daerah => daerah.name"
                    :disabled="form.jenis_dinas == null" @search="wilayahSearch" label="name" class="style-chooser">
                  </v-select>
                </b-col>
                <b-col md="6" class="mb-3">
                  <label for="name">Daerah Tujuan<strong>*</strong></label>
                  <v-select :options="opt.daerah" v-model="form.daerah_tujuan"  :reduce="daerah => daerah.name"
                    :disabled="form.jenis_dinas == null" @search="wilayahSearch" label="name" class="style-chooser">
                  </v-select>
                </b-col>
                <b-col md="6" class="mb-3">
                  <label for="tgl_berangkat">Tgl. Berangkat<strong>*</strong></label>
                  <flat-pickr class="form-control" :config="config.noBackDate" id="tgl_berangkat" v-model="form.tgl_berangkat" @on-change="startDateHandler"></flat-pickr>
                </b-col>
                <b-col md="6" class="mb-3">
                  <label for="tgl_kembali">Tgl. Kembali<strong>*</strong></label>
                  <flat-pickr class="form-control" :config="config.tglBalik" id="tgl_kembali" v-model="form.tgl_kembali" :disabled="form.tgl_berangkat == null"></flat-pickr>
                </b-col>
                <b-col md="12" class="mb-3">
                  <label for="tgl_lahir">Dasar Pelaksana<strong>*</strong></label>
                  <textarea rows="5" class="form-control" v-model="form.dasar_pelaksana" id="dasar_pelaksana"></textarea>
                </b-col>
                <b-col md="12" class="mb-3">
                  <label for="tgl_lahir">Untuk<strong>*</strong></label>
                  <textarea rows="5" class="form-control" v-model="form.untuk" id="untuk"></textarea>
                </b-col>
              </div>
              <div class="float-right">
                <button type="submit" class="btn bg-danger mr-1" @click.prevent="formCancel()">Batal</button>
                <button type="submit" class="btn btn-primary" @click.prevent="formSave()">Simpan</button>
              </div>
            </form>
          </template>
        </card>
      </b-col>
    </b-row>
    <b-modal :no-close-on-backdrop="true" size="xl" id="modalMak" title="Anggaran Perjalanan Dinas">
      <vue-good-table
        :columns="columns"
        :rows="opt.anggaran"
        :search-options="{
          enabled: true
        }">
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'action'">
            <b-button size="sm" @click="modalSelectedHandler(props)" variant="primary" class="mr-2" title="Pilih">
              <i class="dripicons dripicons-pin"></i> Pilih
            </b-button >
          </span>
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>
      </vue-good-table>
      <template #modal-footer="{ cancel }">
        <b-button variant="danger" @click="cancel()">
          Batal
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>
<script>
  import _ from 'lodash'
  import { getDataService, postDataService } from '../../store/modules/crudservices'
  import { dateConfig } from '../../_utils/form-config'
  import flatPickr from 'vue-flatpickr-component'
  import 'flatpickr/dist/flatpickr.css'

  import 'vue-good-table/dist/vue-good-table.css'
  import { VueGoodTable } from 'vue-good-table'

  export default {
    name:'SPTAdd',
    data() {
      return {
        config: {
          spt: {
            wrap: true, 
            altFormat: 'j F Y',
            altInput: true,
            dateFormat: 'Y-m-d',  
          },
          noBackDate: dateConfig.noBackDate,
          tglBalik: dateConfig.basic
        },
        opt: {
          transportasi: ['Darat', 'Laut', 'Udara', 'Darat dan Udara', 'Darat dan Laut'],
          jenis_dinas: ['Dalam Daerah', 'Luar Daerah'],
          daerah: [],
          pegawai: [],
          anggaran: [],
          pttd: [],
        },
        columns:[
          {
            label: 'Kode Rekening',
            field: 'kode_rekening'
          }, {
            label: 'Nama Rekening',
            field: 'nama_rekening'
          }, {
            label: 'Pagu',
            field: 'pagu'
          }, {
            label: 'Anggaran Tersedia',
            field: 'sisa'
          }, {
            label: '',
            field: 'action',
            sortable: false,
            width: '125px',
            tdClass: 'text-center',
          }
        ],
        form:{
          pegawai_id: [],
          pelaksana_id: null,
          tgl_spt: null,
          jenis_dinas: null,
          anggaran_id: null,
          anggaran_text: null,
          tgl_berangkat: null,
          tgl_kembali: null,
          daerah_asal: null,
          daerah_tujuan: null,
          transportasi: null,
          dasar_pelaksana: null,
          untuk: null,
          pttd_id: null,
          pptk_id: null,
          bendahara_id: null,
          pengguna_anggaran_id: null,
          pptk_text: null,
          bendahara_text: null,
          pengguna_anggaran_text: null
        }
      }
    },
    components:{
      flatPickr,
      VueGoodTable
    },
    methods: {
      async MakModalHandler() {
        const url = '/anggaran-search';
        await getDataService(url).then(response => {
          this.opt.anggaran = response.data
        })
        this.$bvModal.show('modalMak')
      },
      initPelaksana() {
        this.form.pegawai_id = []
      },
      initDaerah(value) {
        this.form.tgl_berangkat = null
        this.form.tgl_kembali = null
        this.form.daerah_tujuan = null
        if(value){
          let url = value === 'Luar Daerah' ? '/wilayah/kota?provinsi_id=15' : '/wilayah/kecamatan'
          getDataService(url).then(response => {
            this.opt.daerah = response.data 
            this.form.daerah_asal = value === 'Dalam Daerah' ? 'Siulak' : 'Kabupaten Kerinci'
          })
        } else {
          this.opt.daerah = []
        }
      },
      modalSelectedHandler(value) {
        this.form.anggaran_id = value.row.id
        this.form.anggaran_text = value.row.kode_rekening

        this.form.pengguna_anggaran_id = value.row.pengguna_id
        this.form.pengguna_anggaran_text = value.row.pengguna_name
        this.form.pengguna_anggaran_id = value.row.pengguna_id
        this.form.pengguna_anggaran_text = value.row.pengguna_name
        this.form.pptk_text = value.row.pptk_name
        this.form.pptk_id = value.row.pptk_id
        this.form.bendahara_id = value.row.bendahara_id
        this.form.bendahara_text = value.row.bendahara_name

        this.$bvModal.hide('modalMak')
      },
      wilayahSearch(search, loading) {
        let cari = search.length ? search : ''
        const url = this.form.jenis_dinas === 'Luar Daerah' ? `/wilayah/kota?q=${cari}` : `/wilayah/kecamatan?q=${cari}`
        loading(true)
        this.fetchOptions(loading, url, this.opt)
        // if(search.length) {
        // }
      },
      fetchOptions: _.debounce((loading, url, vm) => {
        getDataService(url).then(response => {
          vm.daerah = response.data

          loading(false)
        })
      },350),
      getPejabat() {
        const url = '/pejabat-search?filter=PTTD';
        getDataService(url).then(response => {
          this.opt.pttd = response.data
        })
      },
      getPegawai() {
        const url = '/pegawai-search?filter=spt';
        getDataService(url).then(response => {
          this.opt.pegawai = response.data
        })
      },
      startDateHandler(selectedDates, sl) {
        let setDate

        this.form.jenis_dinas == 'Dalam Daerah'
          ? setDate = sl
          : setDate = new Date(sl).fp_incr(+1)

        this.$set(this.config.tglBalik, 'minDate', setDate)
      },
      formSave(){
        const form = this.form
        const _data = {
          pelaksana_id: form.pelaksana_id.code,
          pegawai_id: form.pegawai_id,
          tgl_spt: form.tgl_spt,
          jenis_dinas: form.jenis_dinas,
          anggaran_id: form.anggaran_id,
          tgl_berangkat: form.tgl_berangkat,
          tgl_kembali: form.tgl_kembali,
          daerah_asal: form.daerah_asal,
          daerah_tujuan: form.daerah_tujuan,
          transportasi: form.transportasi,
          dasar_pelaksana: form.dasar_pelaksana,
          untuk: form.untuk,
          pttd_id: form.pttd_id,
          pptk_id: form.pptk_id,
          bendahara_id: form.bendahara_id,
          pengguna_anggaran_id: form.pengguna_anggaran_id
        }
        
        postDataService('/spt', _data).then(response => {
          if(response.data.success) this.$router.push('/spt')
        })
      },
      formCancel(){
        this.$router.push('/spt') 
      }
    },
    mounted() {
      this.getPegawai()
      this.getPejabat()
    }
  }
</script>